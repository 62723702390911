<template>
  <!--카드 s-->
  <div :class="viewType?'b-card':'b-list'" @click="goToWebinarVod({
                          id: link,
                          title: title,
                          prof: prof,
                          date: date,
                        })">
    <img :src="thumbnailOrDefault" alt="" >
    <p :class="viewType?'card-text':'list-text'" v-text="title"/>
    <div class="info_less" >
      <p class="date" v-text="date"></p>
      <p class="prof" v-text="prof"></p>
    </div>
    <a @click.prevent.stop="webinarRegister(linkType, {
                          id: link,
                          title: title,
                          prof: prof,
                          date: date,
                        })">
      <button type="button" :class="`btn_${linkType}`"
              >{{ linkTypeName[linkType] }}
      </button>
    </a>
  </div>
  <!--카드 e-->
</template>

<script>
export default {
  name: "WebinarCardListView",
  props: {
    thumbnail: {
      type: String,
      default: require('@/assets/images/invisible.png')
    },
    title: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    prof: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '#'
    },
    linkType: {
      type: String,
      default: 'join'
    },
    viewType: {
      type: Boolean,
      default: true,
    },
    path: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      linkTypeName: {
        'fini': '웨비나 종료',
        'join': '참여하기',
        'comp': '등록완료',
        'regi': '사전등록',
        'replay': '다시보기',
      },
    }
  },
  methods: {
    webinarRegister(type, webinar) {
      if (type === 'regi') {
        this.$emit('webinarRegister', webinar)
      } else if (type === 'join') {
        this.$emit('webinarJoin', webinar)
      } else {
        window.location.href = `${this.basePath}/${webinar.id}`
      }
    },
    goToWebinarVod(webinar) {
      window.location.href = `${this.basePath}/${webinar.id}`
    },
  },
  computed: {
    thumbnailOrDefault() {
      return this.thumbnail ?? require('@/assets/images/invisible.png')
    },
    basePath() {
      return this.path??this.$route.path

    }
  },

}
</script>

<style scoped>

</style>
