<template>
  <div class="section">

    <!--탭메뉴 s-->
    <tab-menu/>
    <!--탭메뉴 e-->

    <p class="tab_exp">웨비나 사전등록에서는 선생님께서 사전등록하셨던 웨비나 라이브 내역을 조회하실 수 있습니다.</p>

    <div class="cardview" v-if="list.length !== 0">
      <!--카드 s-->
      <card-view
          v-for="(item, index) in list"
          :key="index"
          v-bind="item"
          path="/webinar-symposium/webinar-live"
          :view-type="isCardView"/>
      <!--카드 e-->
    </div>
    <div class="btn_area btn_more" v-if="!isLast">
      <button @click="initWebinarLoadMore">Load More</button>
    </div>
    <div class="b_lists" v-if="list.length === 0">
      <!--리스트 없을 때-->
      <div class="nolist">
        <img src="@/assets/images/icon_alert.png" alt="">
        <p>사전등록하신 웨비나 콘텐츠가 없습니다.</p>
      </div>
    </div>

  </div>
</template>

<script>
import CardView from "@/components/Webinar/WebinarCardListView";
import TabMenu from "@/components/MyMenu/TabMenu";

export default {
  name: "PreRegistration",
  components: {
    CardView,
    TabMenu
  },
  data() {
    return {
      isCardView: true,
      viewType: true,
      isLast: true,
      page: 0,
      list: [],
    }
  },
  created() {
    this.initWebinar()
  },
  methods: {
    initWebinar() {
      this.$store.dispatch('initMyPageWebinarList', {
        query: {
        }
      }).then(data => {
        const {webinarList, page, isLast} = data
        this.list = webinarList
        this.page = page
        this.isLast = isLast
      })
    },
    initWebinarLoadMore() {
      this.$store.dispatch('initMyPageWebinarList', {
        query: {
          page: this.page + 1
        }
      }).then(data => {
        const {webinarList, page, isLast} = data
        this.list = [...this.list, ...webinarList]
        this.page = page
        this.isLast = isLast
      })
    },
  },
}
</script>

<style scoped>
.icon_area img {
  /*  아이콘이 왼쪽에 배치됩니다. 임시로 오른쪽에 두겠습니다.*/
  /*  float: right;*/

}
</style>
