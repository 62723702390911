var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.viewType?'b-card':'b-list',on:{"click":function($event){return _vm.goToWebinarVod({
                        id: _vm.link,
                        title: _vm.title,
                        prof: _vm.prof,
                        date: _vm.date,
                      })}}},[_c('img',{attrs:{"src":_vm.thumbnailOrDefault,"alt":""}}),_c('p',{class:_vm.viewType?'card-text':'list-text',domProps:{"textContent":_vm._s(_vm.title)}}),_c('div',{staticClass:"info_less"},[_c('p',{staticClass:"date",domProps:{"textContent":_vm._s(_vm.date)}}),_c('p',{staticClass:"prof",domProps:{"textContent":_vm._s(_vm.prof)}})]),_c('a',{on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.webinarRegister(_vm.linkType, {
                        id: _vm.link,
                        title: _vm.title,
                        prof: _vm.prof,
                        date: _vm.date,
                      })}}},[_c('button',{class:("btn_" + _vm.linkType),attrs:{"type":"button"}},[_vm._v(_vm._s(_vm.linkTypeName[_vm.linkType])+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }